import { QUERY_PRODUCT } from "@/documents/QUERY_PRODUCT";
import { QUERY_PRODUCTS } from "@/documents/QUERY_PRODUCTS";
import { QUERY_HOMEPAGE_COMPONENTS } from "@/documents/QUERY_HOMEPAGE_COMPONENTS";
import { QUERY_STORE } from "@/documents/QUERY_STORE";
import { QUERY_CATEGORIES } from "@/documents/QUERY_CATEGORIES";

// TODO(@oleksii.a): cover all SSR queries
export enum QueryName {
  QUERY_PRODUCT = "QUERY_PRODUCT",
  QUERY_PRODUCTS = "QUERY_PRODUCTS",
  QUERY_HOMEPAGE_COMPONENTS = "QUERY_HOMEPAGE_COMPONENTS",
  QUERY_STORE = "QUERY_STORE",
  QUERY_CATEGORIES = "QUERY_CATEGORIES",
}

export const QueryMap = {
  [QueryName.QUERY_PRODUCT]: QUERY_PRODUCT,
  [QueryName.QUERY_PRODUCTS]: QUERY_PRODUCTS,
  [QueryName.QUERY_HOMEPAGE_COMPONENTS]: QUERY_HOMEPAGE_COMPONENTS,
  [QueryName.QUERY_STORE]: QUERY_STORE,
  [QueryName.QUERY_CATEGORIES]: QUERY_CATEGORIES,
} as const;

import { gql } from "@apollo/client";
import { FRAGMENT_PRODUCT } from "@/documents/fragments/FRAGMENT_PRODUCT";
import { FRAGMENT_PRODUCT_DETAILS } from "@/documents/fragments/FRAGMENT_PRODUCT_DETAILS";

export const QUERY_PRODUCT = gql`
  ${FRAGMENT_PRODUCT}
  ${FRAGMENT_PRODUCT_DETAILS}
  query Product(
    $restaurantId: Int
    $sku: String!
    $includeReplacements: Boolean = false
  ) {
    restaurant(id: $restaurantId) {
      id
      currentStore {
        id
        productBySku(sku: $sku) {
          ...FragmentProduct
          ...FragmentProductDetails
          replacements @include(if: $includeReplacements) {
            ...FragmentProduct
          }
        }
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const FRAGMENT_RESTAURANT = gql`
  fragment FragmentRestaurant on Restaurant {
    id
    name
    userRole
    membership {
      ... on Membership {
        id
        type
        savedAmount
      }
      ... on NullMembership {
        potentialRebate
        potentialSavings
      }
    }
    restaurantNote {
      id
      text
    }
    currentAddress {
      addressLine
      city
      state
      zipcode
      outOfDeliveryZone
    }
  }
`;

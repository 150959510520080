import { gql } from "@apollo/client";

export const FRAGMENT_PRODUCT_DETAILS = gql`
  fragment FragmentProductDetails on Product {
    groupedByCategoryName
    mainCategory {
      id
      name
    }
    subCategory {
      id
      name
    }
    description
  }
`;

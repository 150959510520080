import { gql } from "@apollo/client";

export const FRAGMENT_PRODUCT = gql`
  fragment FragmentProduct on Product {
    id
    sku
    catalogName
    fullName
    brand
    customerExpectedDate
    atp
    inStock
    jit
    upcs
    discontinued
    catchweightItem
    groupedByCategoryName
    nextReceiveDate
    specs {
      id
      name
      value
      specType
    }
    variants(restaurantId: $restaurantId) {
      id
      price
      packageType
      quantityDescription
      itemsCount
      weight
      dimensions {
        length
        width
        height
      }
      currentVariantPricing {
        id
        priceLevels {
          price
          quantity
          discountedPrice
          catchweightPrice
          context {
            price
            uom
          }
        }
      }
      images {
        id
        url
        hqUrl
        priority
      }
    }
  }
`;

import { gql } from "@apollo/client";
import { FRAGMENT_RESTAURANT } from "@/documents/fragments/FRAGMENT_RESTAURANT";

export const FRAGMENT_USER = gql`
  ${FRAGMENT_RESTAURANT}
  fragment FragmentUser on User {
    id
    phone
    email
    name
    restaurants {
      nodes {
        ...FragmentRestaurant
      }
    }
  }
`;

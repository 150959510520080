"use client";
import {
  ApolloQueryResult,
  OperationVariables,
  Unmasked,
  useApolloClient,
} from "@apollo/client";
import { useEffect } from "react";
import { QueryMap, QueryName } from "@/components/QueryWriter/types";

type QueryWriterProps<T, K> = {
  variables: K;
  result: ApolloQueryResult<Unmasked<T>> | null;
  queryName: QueryName;
};

/**
 *
 * NOTE: This component is a workaround for the lack of an Apollo's getDataFromTree for Next.js app router
 * It writes the result of a query to the cache so that it can be used in the same render cycle in client components
 */
function QueryWriter<T, K extends OperationVariables>({
  result,
  variables,
  queryName,
}: QueryWriterProps<T, K>) {
  const client = useApolloClient();

  useEffect(() => {
    if (result?.data) {
      client.writeQuery<T, K>({
        query: QueryMap[queryName],
        variables,
        data: result.data,
      });
    }
  }, []);

  return null;
}

export default QueryWriter;

import { gql } from "@apollo/client";
import { FRAGMENT_SEARCH_RESULT } from "@/documents/fragments/FRAGMENT_SEARCH_RESULT";

export const QUERY_PRODUCTS = gql`
  ${FRAGMENT_SEARCH_RESULT}
  query Products(
    $restaurantId: Int
    $categoryId: Int
    $filters: SearchFilter
    $search: String
    $sort: String
    $page: Int
    $per: Int
    $uniqueId: ID!
    $isNode: Boolean = false
  ) {
    node(uniqueId: $uniqueId) @include(if: $isNode) {
      ... on ProductListComponent {
        products(first: $per) {
          nodes {
            ...FragmentProduct
          }
        }
      }
    }
    search(
      restaurantId: $restaurantId
      categoryId: $categoryId
      page: $page
      per: $per
      filters: $filters
      search: $search
      sort: $sort
    ) @skip(if: $isNode) {
      ...FragmentSearchResult
    }
  }
`;

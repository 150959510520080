import { gql } from "@apollo/client";

export const QUERY_CATEGORIES = gql`
  query Categories($restaurantId: Int) {
    restaurant(id: $restaurantId) {
      id
      currentStore {
        id
        categories(restaurantId: $restaurantId, forSidebar: true) {
          nodes {
            id
            name
            hide
            dynamic
            parentId
            displayOrder
          }
        }
      }
    }
  }
`;

import { gql } from "@apollo/client";
import { FRAGMENT_USER } from "@/documents/fragments/FRAGMENT_USER";

export const QUERY_STORE = gql`
  ${FRAGMENT_USER}
  query Store($restaurantId: Int, $includeUser: Boolean = false) {
    user @include(if: $includeUser) {
      ...FragmentUser
    }
    restaurant(id: $restaurantId) {
      id
      currentStore {
        id
        name
        vendor {
          id
          name
          settings {
            showLowestVariantPrice
            barcodeScanner
            disabledPlatformPayments
          }
          image {
            id
            url
            hqUrl
          }
        }
      }
    }
  }
`;

import { gql } from "@apollo/client";
import { FRAGMENT_PRODUCT } from "@/documents/fragments/FRAGMENT_PRODUCT";

export const FRAGMENT_SEARCH_RESULT = gql`
  ${FRAGMENT_PRODUCT}
  fragment FragmentSearchResult on SearchResult {
    data {
      ...FragmentProduct
    }
    suid
    meta {
      nextPage
      currentPage
      totalCount
      totalPages
    }
    sorts {
      name
      sort
    }
    facets {
      name
      type
      filter
      buckets {
        count
        val
      }
    }
  }
`;

import { gql } from "@apollo/client";
import { FRAGMENT_PRODUCT } from "@/documents/fragments/FRAGMENT_PRODUCT";

const FRAGMENT_PRODUCT_LIST_COMPONENT_DATA = gql`
  ${FRAGMENT_PRODUCT}
  fragment ProductListComponentData on ProductListComponent {
    id
    uniqueId
    priority
    title
    products(first: $first) {
      nodes {
        ...FragmentProduct
      }
    }
  }
`;

const FRAGMENT_BANNER_DATA = gql`
  fragment BannerData on Banner {
    appLink
    ctaText
    frontText
    id
    imageUrl
    name
    priority
    sessionsNumber
    tapBehavior
    tappingText
    link
    versions {
      imageUrl
      size
    }
  }
`;

const FRAGMENT_DEAL_OF_THE_WEEK_COMPONENT_DATA = gql`
  ${FRAGMENT_BANNER_DATA}
  fragment DealOfTheWeekComponentData on DealOfTheWeekComponent {
    banner {
      ...BannerData
    }
    id
    priority
    title
  }
`;

const FRAGMENT_BANNER_LIST_COMPONENT_DATA = gql`
  ${FRAGMENT_BANNER_DATA}
  fragment BannerListComponentData on BannerListComponent {
    banners {
      nodes {
        ...BannerData
      }
    }
    id
    priority
    title
  }
`;

const FRAGMENT_CUISINE_TYPE_COMPONENT_DATA = gql`
  ${FRAGMENT_BANNER_DATA}
  fragment CuisineTypeComponentData on CuisineTypeComponent {
    banner {
      ...BannerData
    }
    id
    priority
    title
  }
`;

const FRAGMENT_SALES_REPRESENTATIVE_COMPONENT_DATA = gql`
  fragment SalesRepresentativeComponentData on SalesRepresentativeComponent {
    id
    uniqueId
    priority
    title
    products(first: $first) {
      nodes {
        id
        sku
        catalogName
        brand
        atp
        inStock
        variants(restaurantId: $restaurantId) {
          price
          packageType
          quantityDescription
          currentVariantPricing {
            priceLevels {
              price
              quantity
              discountedPrice
              context {
                price
                uom
              }
            }
          }
          images {
            url
          }
        }
      }
    }
  }
`;

export const QUERY_HOMEPAGE_COMPONENTS = gql`
  ${FRAGMENT_PRODUCT_LIST_COMPONENT_DATA}
  ${FRAGMENT_BANNER_LIST_COMPONENT_DATA}
  ${FRAGMENT_CUISINE_TYPE_COMPONENT_DATA}
  ${FRAGMENT_DEAL_OF_THE_WEEK_COMPONENT_DATA}
  ${FRAGMENT_SALES_REPRESENTATIVE_COMPONENT_DATA}
  query HomepageComponents($restaurantId: Int, $first: Int!) {
    restaurant(id: $restaurantId) {
      id
      homePage {
        components {
          ... on ProductListComponent {
            ...ProductListComponentData
          }
          ... on BannerListComponent {
            ...BannerListComponentData
          }
          ... on CuisineTypeComponent {
            ...CuisineTypeComponentData
          }
          ... on DealOfTheWeekComponent {
            ...DealOfTheWeekComponentData
          }
          ... on SalesRepresentativeComponent {
            ...SalesRepresentativeComponentData
          }
        }
      }
    }
  }
`;

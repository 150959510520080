"use client";
import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "@chakra-ui/next-js";
import slug from "slug";
import { useParams, usePathname, useSearchParams } from "next/navigation";
import {
  AbsoluteCenter,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DeepPartial } from "@apollo/client/utilities";
import { Category } from "@/documents/__generated__/globalTypes.codegen";
import { useBoolean } from "@chakra-ui/icons";

type LinkedCategory = DeepPartial<Category> & {
  id: number;
  name: string;
  children?: LinkedCategory[];
};

const getCategoryLink = ({
  id,
  name,
  dynamic,
}: DeepPartial<Category>): LinkedCategory => {
  if (!id) {
    throw new Error("Category id is required");
  }

  if (!name) {
    throw new Error("Category name is required");
  }

  return {
    id,
    name,
    dynamic,
  };
};

function buildTreeWithFirstLevelChildren(categories: DeepPartial<Category>[]) {
  const linkedCategories: LinkedCategory[] = [];
  const items: Record<string, LinkedCategory[]> = {};

  categories.forEach((item: DeepPartial<Category>) => {
    const { id, parentId } = item;
    if (!id) {
      throw new Error("Category id is required");
    }
    const category = getCategoryLink(item);

    if (parentId) {
      const children = (items[parentId] = items[parentId] || []);
      children.push(getCategoryLink(category));
    } else {
      category.children = items[id] = items[id] || [];
      linkedCategories.push(category);
    }
  });

  return linkedCategories;
}

type CategoryItemsProps = {
  categories: LinkedCategory[];
  active?: number | "";
  isLoading: boolean;
  onClick: (id: number) => void;
};

const CategoryItems: FC<CategoryItemsProps> = ({
  categories,
  active,
  isLoading,
  onClick,
}) => {
  return categories.map((category) => (
    <AccordionItem key={category.id} borderColor={"white"}>
      {category.id === active ? (
        <AccordionButton
          textAlign="left"
          py={0.5}
          _expanded={{ bg: "blue.50", color: "blue.700" }}
        >
          <Flex flex="1" alignItems="center" gap={2}>
            <Box pr={6} className="relative">
              <Text as="b" color="blue.700">
                {category.name}
              </Text>
              {isLoading && active === category.id && (
                <AbsoluteCenter axis="vertical" right={0}>
                  <Spinner size="xs" />
                </AbsoluteCenter>
              )}
            </Box>
          </Flex>
        </AccordionButton>
      ) : (
        <AccordionButton
          py={0.5}
          onClick={() => {
            onClick(category.id);
          }}
          as={Link}
          textAlign="left"
          className="!no-underline"
          prefetch={false}
          href={`/department/${slug(category.name)}/${category.id}`}
          _expanded={{ bg: "blue.100", color: "blue.700" }}
        >
          <Text flex="1">{category.name}</Text>
        </AccordionButton>
      )}
      <AccordionPanel p={0}>
        <Flex direction="column" align="start">
          {category.children?.map((child) =>
            child.id === active ? (
              <Flex
                py={1}
                w="100%"
                px={6}
                bg="blue.50"
                className="relative"
                flex="1"
                key={child.id}
                gap={2}
                alignItems="center"
              >
                <Text fontSize="sm" color="blue.700">
                  {child.name}
                </Text>
                {isLoading && child.id === active && (
                  <AbsoluteCenter axis="vertical" right={2}>
                    <Spinner color="blue.700" size="xs" />
                  </AbsoluteCenter>
                )}
              </Flex>
            ) : (
              <Link
                fontSize="sm"
                py={1}
                px={6}
                prefetch={false}
                _hover={{ bg: "gray.100" }}
                onClick={() => {
                  onClick(child.id);
                }}
                w="100%"
                key={child.id}
                className="!no-underline"
                href={`/department/${slug(child.name)}/${child.id}`}
              >
                {child.name}
              </Link>
            ),
          )}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  ));
};

type DepartmentsProps = {
  categories: DeepPartial<Category>[];
};

const Departments: FC<DepartmentsProps> = ({ categories }) => {
  const params = useParams();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isLoading, { on, off }] = useBoolean();
  const activeFromParams = useMemo(() => {
    const categoryParams = searchParams.get("category");

    if (categoryParams) {
      const name = categoryParams.split("/").pop();

      return categories.find((category) => category.name === name)?.id;
    }
  }, [categories, searchParams]);
  useEffect(() => {
    off();
    if (activeFromParams) {
      setActive(activeFromParams);
    } else {
      setActive(params.id && Number(params.id));
    }
  }, [off, activeFromParams, pathname]);

  const [active, setActive] = useState(params.id && Number(params.id));
  const { dynamic, nonDynamic, defaultIndex } = useMemo(() => {
    const linkedCategories = buildTreeWithFirstLevelChildren(categories);
    const dynamic = linkedCategories.filter((category) => category.dynamic);
    const nonDynamic = linkedCategories.filter((category) => !category.dynamic);
    const category = categories.find(({ id }) => id === active);
    const defaultIndex = [...dynamic, ...nonDynamic].findIndex(
      ({ id }) => category?.id === id || category?.parentId === id,
    );

    return {
      defaultIndex,
      dynamic,
      nonDynamic,
    };
  }, [active, categories]);

  return (
    <VStack spacing={0} align="stretch">
      <Box className={"sticky top-0 pl-4 py-2 bg-white z-20"}>
        <Heading size="sm">Categories</Heading>
      </Box>
      <Accordion index={defaultIndex} w="100%">
        {dynamic.length > 0 && (
          <>
            <Text className="pl-4" color="gray.400" as="b">
              Specials
            </Text>
            <CategoryItems
              categories={dynamic}
              onClick={(id) => {
                setActive(id);
                on();
              }}
              active={active}
              isLoading={isLoading}
            />
          </>
        )}
        <Text className="pl-4" color="gray.400" as="b">
          Departments
        </Text>
        <CategoryItems
          categories={nonDynamic}
          onClick={(id) => {
            setActive(id);
            on();
          }}
          active={active}
          isLoading={isLoading}
        />
      </Accordion>
    </VStack>
  );
};

export default Departments;
